













































































import { defineComponent, PropType, computed, useFetch, ref, provide, Ref, watch } from '@nuxtjs/composition-api';
import CategoryFilterButton from "~/components/customSFUI/vue/src/components/atoms/CategoryFilterButton/CategoryFilterButton.vue";
import { SortingModel } from "~/modules/catalog/category/composables/useFacet/sortingOptions";
import { useUiHelpers } from "~/composables";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import type { Aggregation } from "~/modules/GraphQL/types";
import { getFilterConfig } from "~/modules/catalog/category/config/FiltersConfig";
import type { SelectedFiltersInterface } from "./useFilters";
import { useFilters } from "./useFilters";
import SelectedFilters from "~/modules/catalog/category/components/filters/FiltersTopbar/SelectedFilters.vue";
import { merge } from 'lodash-es';

export interface UseFiltersProviderInterface {
  selectedFilters: Ref<SelectedFiltersInterface>;
  filters: Ref<Aggregation[]>;
}
export default defineComponent({
  name: 'CategoryTopFilters',
  components:{
    SelectedFilters,
    CheckboxType: () =>
      import(
        "~/modules/catalog/category/components/filters/renderer/CheckboxType.vue"
      ),
    SwatchColorType: () =>
      import(
        "~/modules/catalog/category/components/filters/renderer/SwatchColorType.vue"
      ),
    RadioType: () =>
      import(
        "~/modules/catalog/category/components/filters/renderer/RadioType.vue"
      ),
    YesNoType: () =>
      import(
        "~/modules/catalog/category/components/filters/renderer/YesNoType.vue"
      ),
    CategoryFilterButton,
    SkeletonLoader
  },
  props:{
    sortBy: {
      type: Object as PropType<SortingModel>,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    dropDownFilter: {
      type: Array,
    },
    catUid: {
      type: String,
      required: true,
    },
    sharedFilters: {
      type: Object,
    },
    filters: {
      type: Array as PropType<Aggregation[]>,
      default: [],
    },
    categoryMinPrice: {
      type: Number,
      default: 0,
    },
    categoryMaxPrice: {
      type: Number,
      default: 1000,
    },
  },
  setup(props, { emit }) {
    const {
      selectedFilters,
      selectFilter,
      removeFilter,
      isFilterSelected,
      getCatTopRemovableFilters,
      getSelectedFiltersFromUrl
    } = useFilters();
    const { changeFilters, clearFilters } = useUiHelpers();
    const uiHelpers = useUiHelpers();
    const removableFilters = ref([]);
    const isActiveFastDelivery = computed(() => props.sortBy.selected == "calculated_lead_time_ASC" ? true : false);

    const isValidFilterAttribute = (attribute) => {
        return props.dropDownFilter.filter( filter => filter == attribute)?.[0];
    }

    provide("UseFiltersProvider", {
      isFilterSelected,
      selectedFilters,
      filters: props.filters,
    });
    
    const fastDeliverySorting = (isFastDelivery: boolean) => {
      const sort = isFastDelivery ? "position_DESC" : "calculated_lead_time_ASC";
      uiHelpers.changeSorting(sort,false);
      emit("reloadProducts");
    };

    const doApplyFilters = (filter, event) => {
      selectFilter(filter, event)
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      if (window?.scroll) {
        window.scroll(0, 0);
      }
      emit("sharedFilterUpdate", selectedFilters.value);
      emit("reloadProducts");
      emit("close");
    };

    const updateRemovableFilters = () => {
      removableFilters.value = getCatTopRemovableFilters(
        props.filters,
        selectedFilters.value,
        props.dropDownFilter,
      );
    };

    const doRemoveFilter = ({ id, value }: { id: string; value: string }) => {
      removeFilter(id, value);
      changeFilters(selectedFilters.value, false);
      updateRemovableFilters();
      emit("sharedFilterUpdate", selectedFilters.value);
      emit("reloadProducts");
      emit("close");
    };

    const doClearFilters = () => {
      clearFilters(false);
      selectedFilters.value = {};
      updateRemovableFilters();
      emit("sharedFilterUpdate", selectedFilters.value);
      emit("reloadProducts");
      emit("close");
    };
    
    watch(
       () => props.sharedFilters,
       (newValue) => {
        selectedFilters.value = {};
        selectedFilters.value = merge({}, selectedFilters.value, newValue);
        updateRemovableFilters();
      },
      {deep: true, immediate: true}
    );

    useFetch(async () => {      
      updateRemovableFilters();
    });

    watch(
      () => props.filters,
      (newValue) => {
        updateRemovableFilters();
      },
    );

    return{
      isActiveFastDelivery,
      fastDeliverySorting,
      isValidFilterAttribute,
      selectFilter,
      getFilterConfig,
      removableFilters,
      doApplyFilters,
      doRemoveFilter,
      doClearFilters,
      getSelectedFiltersFromUrl
    }

  },
});
