import { useApi } from '~/composables/useApi';

export const useFtReviewScore= () => {
  const { query } = useApi();

  const getReviewScore:any = async (product_uid) =>{
    const myGraphqlQuery = `
      query {
        getreviewscore(product_id: "${product_uid}") {
          review_total_score
          review_chart_score {
            one
            two
            three
            four
            five
          }
        }
      }
      `;  
    const { data } = await query(myGraphqlQuery);

    return {
      data,
    };
  }
  
  return {
    getReviewScore,
  }
}


export default useFtReviewScore;