import { render, staticRenderFns } from "./Cms.vue?vue&type=template&id=1897b56a&scoped=true&"
import script from "./Cms.vue?vue&type=script&lang=ts&"
export * from "./Cms.vue?vue&type=script&lang=ts&"
import style0 from "./Cms.vue?vue&type=style&index=0&id=1897b56a&prod&lang=scss&scoped=true&"
import style1 from "./Cms.vue?vue&type=style&index=1&id=1897b56a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1897b56a",
  null
  
)

export default component.exports