import { computed, ref, useContext } from "@nuxtjs/composition-api";
import { useApi } from '~/composables/useApi';
import type { Product } from '~/modules/catalog/product/types';
import moment from 'moment';
import getFacetDYDataQuery from '~/modules/catalog/category/composables/useFacet/getFacetDYData.gql';

export const useFtDynamicYieldGrid = () => {
    const productSkus = ref([]);
    const { query } = useApi();
    const productSku:any = ref("");
    const product = ref<Product | null>(null);

    const getPriceRangeBySku = (fetchProductStockStatus,sku)  => {
        const items = fetchProductStockStatus.data.dyproductinit.items;
        const product = items.find(item => item.sku === sku);
        if (product) {
            return product;
        } else {
            return false;
        }
      }
  
    const getProductPrice = (price_range) => {
        let regular = 0;
        let special = null;
        let maximum = null;
        let final = null;
        if (price_range) {
          regular = price_range.minimum_price.regular_price.value;
          final = price_range.minimum_price.final_price.value;
          maximum = price_range.maximum_price.final_price.value;
  
          if (final < regular) {
            special = final;
          }
        }
  
        return {
            regular,
            special,
            maximum,
            final,
        };
    };

    const fetchDYGridLayout = async () => {
        const DYCSRecommendation = document.querySelector('.category-layout');
        if(DYCSRecommendation){
          const dyRecsId = DYCSRecommendation.id;
          const parentElement = document.getElementById(dyRecsId);
          const divElements = parentElement.querySelectorAll('div.card');
          divElements.forEach(function(div) {
            const productSku = div.getAttribute('data-dy-product-sku');
            productSkus.value.push(productSku);
          });
          const fetchProductStockStatus :any = await getDYProductQueryData();

          divElements.forEach(function(div) {
            const productSku = div.getAttribute('data-dy-product-sku');
            const isEnabledProductDetail = getPriceRangeBySku(fetchProductStockStatus,productSku);
            const isOOSProduct = fetchProductStockStatus.data.dyproductinit?.items.some(item => item.sku ===productSku && item.stock_status == "OUT_OF_STOCK");
  
            if(!isEnabledProductDetail || isOOSProduct){
              div.remove();
            }else{
              const imageElement = div.querySelector('.sf-image');
              const image2Element = div.querySelector('.secondary-image');
              imageElement.setAttribute('src',isEnabledProductDetail.thumbnail.url); 
              if(isEnabledProductDetail.secondary_image.url){
                image2Element.setAttribute('src',isEnabledProductDetail.secondary_image.url);
              }else{
                image2Element.setAttribute('src',isEnabledProductDetail.thumbnail.url);
              }
              const productPrice = computed(() => getProductPrice(isEnabledProductDetail.price_range).regular);
              const productSpecialPrice = computed(() => getProductPrice(isEnabledProductDetail.price_range).special);
              const productCrossedOutPrice = computed(() => isEnabledProductDetail.crossed_out_list_price ?? 0);
              const hideCrossOutPrice = computed(() => isEnabledProductDetail.crossed_out_list_price ? isEnabledProductDetail.crossed_out_list_price <= getProductPrice(isEnabledProductDetail.price_range).regular : true );
              
              let newprice:any = 0;
              newprice =  productSpecialPrice.value ? productSpecialPrice.value: productPrice.value;
              let oldprice:any = 0;
              if (productSpecialPrice.value){
                oldprice =  productCrossedOutPrice.value > productPrice.value ? productCrossedOutPrice.value : productPrice.value;
              }else{
                oldprice = productCrossedOutPrice.value;
              }
  
              const newPriceElement = div.querySelector('.new-price');
              newPriceElement.innerHTML = 'S$ '+newprice.toFixed(2); 
  
              const oldPriceElement = div.querySelector('.old-price');
              if(oldPriceElement){
                if(oldprice == 0){
                  oldPriceElement.remove();
                }else{
                  oldPriceElement.innerHTML = 'S$ '+oldprice.toFixed(2); 
                }
              }
              const datacatetxt = "Width (cm): "+isEnabledProductDetail.width+" ,Height (cm): "+isEnabledProductDetail.height+" ,depth (cm): "+isEnabledProductDetail.depth;
              const elementCategoryDimension = div.querySelector('.category-dimension__txt');
              if(elementCategoryDimension){
                if(isEnabledProductDetail.width){
                  elementCategoryDimension.innerHTML = datacatetxt; 
                }else{
                  elementCategoryDimension.remove()
                }
              }
              const leadDate = moment().add(isEnabledProductDetail.calculated_lead_time, 'd').format("ddd, D MMM 'YY");
              const leadTime = computed(() => isEnabledProductDetail.calculated_lead_time >= 0 ? isEnabledProductDetail.calculated_lead_time : -1);

              const dataLeadDateTime = ref('');
              if(leadTime.value == 0){
                dataLeadDateTime.value = 'Order Now, Deliver Today';
              }else if(leadTime.value == 1){
                dataLeadDateTime.value = 'Order Now, Deliver Tomorrow';
              }else if(leadTime.value >= 2 && leadTime.value <= 7){
                dataLeadDateTime.value = 'Deliver in' + ' ' + leadTime.value + ' ' + 'Days';
              }else{
                dataLeadDateTime.value = 'Deliver from' + ' ' + leadDate;
              }

              const elementLeadTime = div.querySelector('.category-item-lead-time__txt');
              elementLeadTime.innerHTML = dataLeadDateTime.value;
            }
          });  
          const dySkeletonGridElement = document.querySelector('.dySkeleton_grid');
          if(dySkeletonGridElement){
            dySkeletonGridElement.setAttribute('style', 'display:none');
          }
          
          const elementRecsGrid = document.querySelector('.dyGridCategory');
          if(elementRecsGrid){
            elementRecsGrid.removeAttribute('style');
          }
          DYCSRecommendation.classList.replace('dyGridCategory', 'dyGridCategoryDone');
        }
    }

    const getDYProductQueryData = async () => {
      const skusArray = productSkus.value;
      const myGraphqlQuery =`
          query {
              dyproductinit(skus: ${JSON.stringify(skusArray)}) {
                items {
                  sku
                  name
                  calculated_lead_time
                  crossed_out_list_price
                  stock_status
                  width
                  height
                  depth
                  length
                  diameter
                  thumbnail{
                    url
                  }
                  secondary_image{
                    url
                  }
                  price_range {
                    maximum_price {
                        final_price {
                          currency
                          value
                        }
                        regular_price {
                          currency
                          value
                        }
                    }
                    minimum_price {
                        final_price {
                          currency
                          value
                        }
                        regular_price {
                          currency
                          value
                        }
                    }
                  }
                }
              }
            }`
      return await query(myGraphqlQuery);
    }

    const fetchSmartObjectGrid = async () => {
      const getSmartObj = document.querySelector('.dy_recommendations_grid');
      if(getSmartObj){
        const getSmartObjId = getSmartObj.id;
        const getSmartObjData = getSmartObj.getAttribute('data-id');
        DYO.smartObject(getSmartObjData, {target: getSmartObjId, inline: true});
      }
    }
    return {
        fetchDYGridLayout,
        fetchSmartObjectGrid,
    };
}

export default useFtDynamicYieldGrid;