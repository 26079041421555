
















































import { defineComponent } from "@nuxtjs/composition-api";
import SfSkeleton from "@storefront-ui/vue/src/components/atoms/SfSkeleton/SfSkeleton.vue";

export default defineComponent({
  name: "CmsSkeletonLoader",
  components: {
    SfSkeleton,
  },
});
