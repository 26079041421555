<template><div id="GA4SelectItem"></div></template>
<script>
import { onMounted } from "@nuxtjs/composition-api";
import { useUser } from '~/modules/customer/composables/useUser';
import { sha256 } from 'js-sha256';

export default {
  name: "GA4SelectItem",
  props: {
    product: {
    type: Object,
    required: true,
    },
    activeCategory:{
    type: String,
    },
    triggerSelectItemTrack: {
    type: Boolean
    },
  },
  methods: {
    trackSelectItem() {
      if(this?.user?.email){
        const userId = sha256.hex(this.user.email);
        this.$gtag.event("userIdSet", {
          'event_label': "userIdSet",
          'currency': this.product?.cateProductDetails?.price_range.maximum_price?.final_price?.currency,
          'item_list_name': 'Category - '+this.activeCategory ?? this.product?.cateProductDetails?.product_category,
          'items': [
            {
              item_id: this.product?.cateProductDetails?.sku ?? "",
              item_name: this.product?.cateProductDetails?.name ?? "",
              price: this.product?.cateProductDetails?.price_range?.maximum_price?.final_price.value ?? "",
              item_category: this?.product?.cateProductDetails?.product_category ?? "",
              item_brand: this?.product?.cateProductDetails?.product_brand_label ?? "",
            }
          ],
          'user_id': userId
        });
      }

      this.$gtag.event("select_item", {
        'event_label': "select_item",
        'currency': this.product?.cateProductDetails?.price_range.maximum_price?.final_price?.currency, 
        'item_list_name': 'Category - '+this.activeCategory ?? this.product?.cateProductDetails?.product_category,
        'items': [
          {
            item_id: this.product?.cateProductDetails?.sku ?? "",
            item_name: this.product?.cateProductDetails?.name ?? "",
            price: this.product?.cateProductDetails?.price_range?.maximum_price?.final_price.value ?? "",
            item_category: this?.product?.cateProductDetails?.product_category ?? "",
            item_brand: this?.product?.cateProductDetails?.product_brand_label ?? "",
          }
        ]
      });
    },
  },
  watch: {
    // cart quantity change will trigger to run add or remove cart event.
    triggerSelectItemTrack() {
      if(this.triggerSelectItemTrack){
        this.trackSelectItem();
        this.$emit("triggerSelectItemTrackFlag",false);
      }
    },
  },
  setup() {
    const { user, load: loadUser } = useUser();
    onMounted(async () => {
      if (user.value === null) {
        await loadUser();
      }
    });
    return{
      user
    }
  }
};
</script>

  