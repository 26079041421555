





















import {
  ref,
  computed,
  useContext,
  useRoute,
  defineComponent,
  useFetch,
  onMounted,
} from '@nuxtjs/composition-api';
import { merge } from 'lodash-es';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { SfBreadcrumbs, SfLoader } from '@storefront-ui/vue';
import { getBreadcrumbs } from '~/modules/catalog/product/getters/productGetters';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { usePageStore } from '~/stores/page';
import { ProductTypeEnum } from '~/modules/catalog/product/enums/ProductTypeEnum';
import { useWishlist, useApi } from '~/composables';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import type { Product } from '~/modules/catalog/product/types';
import type { ProductDetailsQuery } from '~/modules/GraphQL/types';
import ProductSkeleton from '~/modules/fortytwo/product/components/ProductSkeleton.vue';
import getProductPriceBySkuGql from '~/modules/catalog/product/queries/getProductPriceBySku.gql';
import getProductAvailableConfigOptionGql from '~/modules/catalog/product/queries/getProductAvailableConfigOption.gql';
import useFtProductInfo from '~/modules/fortytwo/product/composables/useFtProductInfo';
import useFtReviewScore from '~/modules/fortytwo/product/composables/useFtReviewScore';
import CustomHead from '~/dy/customHead.vue';

export default defineComponent({
  name: 'ProductPage',
  components: {
    ProductSkeleton,
    SimpleProduct: () => import('~/modules/fortytwo/product/components/simple/SimpleProduct.vue'),
    BundleProduct: () => import('~/modules/fortytwo/product/components/bundle/BundleProduct.vue'),
    ConfigurableProduct: () => import('~/modules/fortytwo/product/components/configurable/ConfigurableProduct.vue'),
    GroupedProduct: () => import('~/modules/catalog/product/components/product-types/grouped/GroupedProduct.vue'),
    LoadWhenVisible,
    SfBreadcrumbs,
    SfLoader,
    CustomHead
  },
  props:["routeData"],
  transition: 'fade',
  setup(props) {
    //const { routeData } = usePageStore();
    const routeData = props.routeData;
    const dyHeadData = ref([]);
    const { query } = useApi();
    const product = ref<Product | null>(null);
    const { addTags } = useCache();
    const { localePath } = useContext();
    const route = useRoute();
    const { getProductDetails, loading } = useProduct();
    const { error: nuxtError } = useContext();
    const { load: loadWishlist } = useWishlist();
    const wishlistStore = useWishlistStore();
    const validFilters = ref([]);
    const isProductExtendedDataLoading = ref(false);
    const breadcrumbs = computed(() => {
      const currentUrlPath = route.value?.path.substring(0, route.value?.path.lastIndexOf('/')).substring(1);
      const categoriesIndex = product.value?.categories?.findIndex(link => link.url_path == currentUrlPath);
      const productCategories = categoriesIndex < 0 ? null : product.value?.categories?.[categoriesIndex];

      return getBreadcrumbs(
        product.value,
        productCategories,
      ).map((breadcrumb) => ({ ...breadcrumb, link: breadcrumb.link }));
    });

    const getBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: routeData.sku,
        },
      },
      configurations: Object.entries(route.value.query)
        .filter((config) => validFilters.value.indexOf(config[0]) >= 0)
        .map(
          (config) => config[1],
        ) as string[],
    });

    // eslint-disable-next-line no-underscore-dangle
    const renderer = computed(() => product.value?.__typename ?? ProductTypeEnum.SIMPLE_PRODUCT);

    const fetchProductBaseData = async (searchQuery = getBaseSearchQuery()) => {
      const result = await getProductDetails({
        ...searchQuery,
      });
      product.value = merge({}, product.value, result.items[0] as Product ?? null);
    };

    const fetchProductExtendedData = async (searchQuery = getBaseSearchQuery(), dataFetch:any = {}) => {
      isProductExtendedDataLoading.value = true;
      const { data }:any = await query<ProductDetailsQuery>(getProductPriceBySkuGql, searchQuery);

      if(product.value?.__typename == "ConfigurableProduct" && dataFetch.selectedConfig && data?.products?.items[0]?.configurable_product_options_selection?.options_available_for_selection){
        /**clear all the available option array from getProductPriceBySkuGql data, 
           the available option array will update at the below query call.**/
        data.products.items[0].configurable_product_options_selection.options_available_for_selection = [];
      }
      
      product.value = await merge({}, product.value, data.products?.items?.[0] as Product);

      if(product.value?.__typename == "ConfigurableProduct"){
        // this is initial query call for update available option and trigger by no selection and 1 config selection with invalid config uid.
        if((product?.value?.configurable_options?.length == 1 && !dataFetch?.isValidConfigOptionId) || dataFetch?.selectedConfig?.length == 0){

          const getAvailableOptionQuery = () => ({
            filter: {
              sku: {
                eq: routeData.sku,
              },
            },
            configurations: [],
          });
          
          const { data : configOption }:any = await query<ProductDetailsQuery>(getProductAvailableConfigOptionGql, getAvailableOptionQuery());

          if(product?.value?.configurable_product_options_selection?.options_available_for_selection?.[0]?.option_value_uids){
            product.value.configurable_product_options_selection.options_available_for_selection[0].option_value_uids = [];
          }
          if(!dataFetch?.isValidConfigOptionId){
            Reflect.deleteProperty(configOption.products?.items?.[0]?.configurable_product_options_selection, "variant");
          }
          product.value = await merge({}, product.value, configOption?.products?.items?.[0] as Product);

        }else{
          
          // to get the combination of selected config option.
          const items = dataFetch?.selectedConfig;
          const attributeCodeArray = [];
          if(product?.value?.configurable_options?.length == items?.length){
            for (let i = 0; i < items?.length; i++) {
                if(product?.value?.configurable_options?.length == 2){
                    attributeCodeArray.push([items[i]]);
                }
                for (let j = i + 1; j < items?.length; j++) {
                  if(product?.value?.configurable_options?.length == 3){
                    attributeCodeArray.push([items[i], items[j]]);
                  }
                  for (let k = j + 1; k < items?.length; k++) {
                    if(product?.value?.configurable_options?.length == 4){
                      attributeCodeArray.push([items[i], items[j], items[k]]);
                    }
                    for (let l = k + 1; l < items?.length; l++) {
                      if(product?.value?.configurable_options?.length == 5){
                        attributeCodeArray.push([items[i], items[j], items[k], items[l]]);
                      }
                    }
                  }
              }
            }
          }else{
            for (let i = 0; i < items?.length; i++) {
              if (items.length > 1 && dataFetch.selectedConfigOption.attributeCode == items[i]){
                attributeCodeArray.push([items[i]]);
              }else if(items.length == 1){
                if(dataFetch.selectedConfigOption.attributeCode == items[i]){
                  attributeCodeArray.push([items[i]]);
                }else{
                  let attributeCodeToArray = [];
                  attributeCodeToArray.push(dataFetch.selectedConfigOption.attributeCode);
                  attributeCodeArray.push(attributeCodeToArray);
                }
              }
              for (let j = i + 1; j < items?.length; j++) {
                    attributeCodeArray.push([items[i], items[j]]);
                for (let k = j + 1; k < items?.length; k++) {
                    attributeCodeArray.push([items[i], items[j], items[k]]);
                  for (let l = k + 1; l < items?.length; l++) {
                    attributeCodeArray.push([items[i], items[j], items[k], items[l]]);
                  }
                }
              }
            }
          }

          attributeCodeArray.sort((a, b) => a.length - b.length);

          // function to get the available option based on the combination set of selected config option.
          const fetchAvailableOption = async (xAttributeCode) => {
            let selectedOption = [];
              xAttributeCode.forEach((yAttributeCode) => {
                if(dataFetch.productConfigurationCode[yAttributeCode]){
                  selectedOption.push(dataFetch.productConfigurationCode[yAttributeCode]);
                }
              });

              const getAvailableOptionQuery = () => ({
                filter: {
                  sku: {
                    eq: routeData.sku,
                  },
                },
                configurations: selectedOption,
              });

              const { data : configOption }:any = await query<ProductDetailsQuery>(getProductAvailableConfigOptionGql, getAvailableOptionQuery());
  
              if(configOption.products?.items?.[0]?.configurable_product_options_selection){
                xAttributeCode?.forEach((item) => {
                  // to clear return data array, because selected config option data return with only 1 array so need to make it clear before merging to product object for avoiding cases like array not accurate.
                  if(configOption.products?.items?.[0]?.configurable_product_options_selection?.options_available_for_selection.filter(filter => filter.attribute_code == item)?.[0]?.option_value_uids?.length == 1){
                    configOption.products?.items?.[0]?.configurable_product_options_selection?.options_available_for_selection.filter(filter => filter.attribute_code == item)?.[0]?.option_value_uids.pop();
                  }
                });

                // to clear none selected config option array set to make it clear before merging to product object for avoiding cases like array not accurate
                product.value.configurable_product_options_selection.options_available_for_selection.forEach((item) => {
                  let index = xAttributeCode?.indexOf(item.attribute_code);
                  if (index < 0) {
                    product.value.configurable_product_options_selection.options_available_for_selection.filter(filter => filter.attribute_code == item.attribute_code)[0].option_value_uids = [];
                  }
                });
                Reflect.deleteProperty(configOption.products?.items?.[0]?.configurable_product_options_selection, "variant");
                product.value = await merge({}, product.value, configOption.products?.items?.[0] as Product);

              }else{

                // trigger this while selected the invalid config option.
                const getAvailableOptionQuery = () => ({
                  filter: {
                    sku: {
                      eq: routeData.sku,
                    },
                  },
                  configurations: [],
                });

                const { data : configOption }:any = await query<ProductDetailsQuery>(getProductAvailableConfigOptionGql, getAvailableOptionQuery());
                Reflect.deleteProperty(configOption.products?.items?.[0]?.configurable_product_options_selection, "variant");
                product.value = await merge({}, product.value, configOption.products?.items?.[0] as Product);

              }
          };
          
          // make the loop in queue to avoid product object merging by shorter array set at last. so need to make sure shorter array config option updated first.
          for(const xAttributeCode of attributeCodeArray){
            await Promise.all([fetchAvailableOption(xAttributeCode)]);
          }

        }
      }

      if(product?.value?.sku){
        dyHeadData.value.pop();
        if(product?.value?.__typename == "SimpleProduct"){
        dyHeadData.value.push(product?.value?.msbc_item_no);
        }else{
          dyHeadData.value.push(product?.value?.sku);
        }
      }

      // control to add or remove "selected_media_gallery" property. 
      if(product.value?.__typename == "ConfigurableProduct" && searchQuery.configurations.length > 0){
        product.value.selected_media_gallery = merge({},data.products.items?.[0]?.configurable_product_options_selection ?? "");
      }else{
        Reflect.deleteProperty(product.value, 'selected_media_gallery');
      }
      if (product.value?.__typename == "ConfigurableProduct" && product.value?.configurable_product_options_selection?.variant?.uid) {
        await fetchConfigCustomProductInfo(product.value?.configurable_product_options_selection?.variant?.uid);
      }
      isProductExtendedDataLoading.value = false;
    };

    useFetch(async () => {
      await fetchProductBaseData();
      await fetchCustomReviewScore(product.value.uid);
      await fetchProductExtendedData();

      product.value?.configurable_options?.forEach((option)=> validFilters.value.push(option.attribute_uid));
      
      if (Boolean(product?.value?.sku) === false) nuxtError({ statusCode: 404 });
      const tags = [
        {
          prefix: CacheTagPrefix.View,
          value: 'product',
          // value: `product-${routeData.sku}`,
        },
      ];

      const productTags = [{
        prefix: CacheTagPrefix.Product,
        value: product.value.uid,
      }];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      addTags([...tags, ...productTags]);
    });
  
    onMounted(async () => {
      if (!wishlistStore?.wishlist?.id) {
        await loadWishlist();
      }
    });

    // get custom product attribute
    const { getProductInfo } = useFtProductInfo();

    const fetchConfigCustomProductInfo = async (product_uid) => {
    const { data } = await getProductInfo(product_uid);
    if(product.value.crossed_out_list_price){
      product.value.crossed_out_list_price = data.getProductInfo.crossed_out_list_price;
    }
    };

    // get custom product review score
    const { getReviewScore } = useFtReviewScore();
    const fetchCustomReviewScore = async (product_uid) => {
      const { data } = await getReviewScore(product_uid);
      product.value = merge({}, product.value, data);
    };

    return {
      renderer,
      loading,
      breadcrumbs,
      product,
      fetchProduct: fetchProductExtendedData,
      dyHeadData,
      isProductExtendedDataLoading,
    };
  },
  head() {
    return getMetaInfo(this.product);
  },
});
