import { useApi } from '~/composables/useApi';

export const useFtProductInfo = () => {
  const { query } = useApi();

  const getProductInfo:any = async (product_uid) =>{
    const myGraphqlQuery = `
      query {
        getProductInfo(product_id: "${product_uid}"){
            warranty
            guarantees
            assembly_type
            refundable
            staff_rating
            category
            brand
            additional_data {
              label
              value
              code
            }
            crossed_out_list_price
        }
      }
      `;  
    const { data } = await query(myGraphqlQuery);

    return {
      data,
    };
  }
  
  return {
    getProductInfo,
  }
}


export default useFtProductInfo;