















import { SfLoader, SfHeading } from "@storefront-ui/vue";
import {
  defineComponent,
  ref,
  useFetch,
  useContext,
  onMounted,
} from "@nuxtjs/composition-api";
import { useCache, CacheTagPrefix } from "@vue-storefront/cache";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import { useContent } from "~/composables";
import type { CmsPage } from "~/modules/GraphQL/types";
import HTMLContent from "~/components/HTMLContent.vue";
import CmsSkeletonLoader from "~/components/SkeletonLoader/CmsSkeletonLoader.vue";
import CustomHead from '~/dy/customHead.vue';
import { useFtDynamicYieldList } from '~/dy/useFtDynamicYieldList';
import { useFtDynamicYieldGrid } from '~/dy/useFtDynamicYieldGrid';
import useFtSendFacebookPixel from '~/composables/FortyTwo/useFtSendFacebookPixel';

export default defineComponent({
  name: "CmsPage",
  components: {
    HTMLContent,
    SfLoader,
    SfHeading,
    CmsSkeletonLoader,
    CustomHead,
  },
  props:[
    "routeData"
  ],
  setup(props) {
    const routeData = props.routeData;
    const { addTags } = useCache();
    const { error: nuxtError } = useContext();
    const { loadPage, loading, error } = useContent();
    const isScriptLoaded = ref(false);
    const { addtothecart,fetchDYLayout,fetchSmartObjectList } = useFtDynamicYieldList();
    const { fetchDYGridLayout,fetchSmartObjectGrid } = useFtDynamicYieldGrid();
    const { sendFacebookPixel } = useFtSendFacebookPixel();
    const page = ref<CmsPage | null>(null);

    useFetch(async () => {
      page.value = await loadPage({ identifier: routeData.identifier });

      if (error?.value?.page || !page.value) nuxtError({ statusCode: 404 });

      addTags([{ prefix: CacheTagPrefix.View, value: routeData.identifier }]);
    });

    onMounted(async () => {
      if (process.client) {
        window.addtothecart = addtothecart;
        window.fetchDYGridLayout = fetchDYGridLayout;
        window.fetchDYLayout = fetchDYLayout;
      }

      DYO.waitForElement('.dy_recommendations_grid', function() {
        fetchSmartObjectGrid();
      }, 1, 200, 15);

      DYO.waitForElement('.dy_recommendations_list', function() {
        fetchSmartObjectList();
      }, 1, 200, 15);
      
      // DYO.waitForElement('.dyGridCategory', function() {
      // fetchDYGridLayout();
      // }, 1, 500, 15);

      // DYO.waitForElement('.dyRecs', function() {
      //   fetchDYLayout();
      // }, 1, 500, 15);

      // facabook pixel and conversions api
      await sendFacebookPixel('PageView', '');
    });

    return {
      page,
      loading,
      addtothecart,
      fetchDYLayout,
      fetchSmartObjectList,
      fetchSmartObjectGrid,
      fetchDYGridLayout,
      isScriptLoaded,
    };
  },
  head() {
    return getMetaInfo(this.page);
  },
});
