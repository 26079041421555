














import { defineComponent } from "@nuxtjs/composition-api";
import { SfImage } from "@storefront-ui/vue";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";

export default defineComponent({
  name: "FortytwoBeautifulwithempty",
  components: {
    SfImage,
    SkeletonLoader,
  },
  
});
