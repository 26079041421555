export default `
  query getProductAvailableConfigOption(
    $filter: ProductAttributeFilterInput,
    $configurations: [ID!]
  ) {
    products(filter: $filter) {
      items {
        ... on ConfigurableProduct {
          configurable_product_options_selection(configurableOptionValueUids: $configurations) {
            options_available_for_selection {
              attribute_code
              option_value_uids
            }
            variant {
              uid
              sku
              name
            }
          }
        }
      }
    }
  }
`;
