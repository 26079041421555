












import {
  defineComponent, useContext, useFetch, ref, onMounted, useAsync
} from '@nuxtjs/composition-api';
import { SfBreadcrumbs } from '@storefront-ui/vue';
import { useUiHelpers } from '~/composables';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import { Breadcrumb } from '~/modules/catalog/types';
import CustomHead from '~/dy/customHead.vue';

export default defineComponent({
  components: { SfBreadcrumbs, CustomHead },
  setup() {
    const { getCatLink } = useUiHelpers();
    const { localePath } = useContext();
    const dyHeadData = ref([]);

    const {
      categoryAncestors, isCategoryTreeLoaded, loadCategoryTree,
    } = useTraverseCategory();
    const breadcrumbs = ref<Breadcrumb[]>([]);

    useAsync(async () => {
      if (!isCategoryTreeLoaded.value) {
        await loadCategoryTree();
      }

      breadcrumbs.value = categoryAncestors.value.slice(0, -1).map((category) => ({
        text: category.name,
        link: getCatLink(category),
      }));

      await categoryAncestors.value.forEach(category => {
      dyHeadData.value.push(category.name);
      });

    });

    

    return {
      breadcrumbs,
      dyHeadData,
    };
  },
});
