import { computed, ref, useContext } from "@nuxtjs/composition-api";
import { useConfig } from "~/composables";
import { useApi } from '~/composables/useApi';
import { useAddToCart } from "~/helpers/cart/addToCart";
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import type { Product } from '~/modules/catalog/product/types';
import { merge } from 'lodash-es';
import { useUiNotification } from '~/composables/useUiNotification';

export const useFtDynamicYieldList = () => {
    const { config } = useConfig();
    const productSkus = ref([]);
    const { app } = useContext();
    const baseMediaUrl = config.value.base_media_url;
    const { query } = useApi();
    const productSku:any = ref("");
    const { addItemToCart, error: cartError } = useAddToCart();
    const { getProductList } = useProduct();
    const product = ref<Product | null>(null);
    const {send: sendNotification} = useUiNotification();

    const getPriceRangeBySku = (fetchProductStockStatus,sku)  => {
        const items = fetchProductStockStatus.data.dyproductinit.items;
        const product = items.find(item => item.sku === sku);
        if (product) {
            return product;
        } else {
            return false;
        }
      }
  
    const getProductPrice = (price_range) => {
        let regular = 0;
        let special = null;
        let maximum = null;
        let final = null;
        if (price_range) {
          regular = price_range.minimum_price.regular_price.value;
          final = price_range.minimum_price.final_price.value;
          maximum = price_range.maximum_price.final_price.value;
  
          if (final < regular) {
            special = final;
          }
        }
  
        return {
            regular,
            special,
            maximum,
            final,
        };
    };

    const fetchDYLayout = async () => {
        const DYCSRecommendation = document.querySelector('.dyRecs');
        if(DYCSRecommendation){
          const dyRecsId = DYCSRecommendation.id;
          const parentElement = document.getElementById(dyRecsId);
          const divElements = parentElement.querySelectorAll('div.item');
  
          divElements.forEach(function(div) {
            const productSku = div.getAttribute('data-dy-product-sku');
            productSkus.value.push(productSku);
          });

          const fetchProductStockStatus :any = await getDYProductQueryData();
  
          divElements.forEach(function(div) {
            const productSku = div.getAttribute('data-dy-product-sku');
            const isEnabledProductDetail = getPriceRangeBySku(fetchProductStockStatus,productSku);
            const isOOSProduct = fetchProductStockStatus.data.dyproductinit?.items.some(item => item.sku ===productSku && item.stock_status == "OUT_OF_STOCK");
            
            if(!isEnabledProductDetail || isOOSProduct){
              div.remove();
            }else{
              const imageElement = div.querySelector('.sf-image');
              const image2Element = div.querySelector('.secondary-image');
              imageElement.setAttribute('src',isEnabledProductDetail.thumbnail.url); 
              image2Element.setAttribute('src',isEnabledProductDetail.thumbnail.url);
  
              const productPrice = computed(() => getProductPrice(isEnabledProductDetail.price_range).regular);
              const productSpecialPrice = computed(() => getProductPrice(isEnabledProductDetail.price_range).special);
              const productCrossedOutPrice = computed(() => isEnabledProductDetail.crossed_out_list_price ?? 0);
              const hideCrossOutPrice = computed(() => isEnabledProductDetail.crossed_out_list_price ? isEnabledProductDetail.crossed_out_list_price <= getProductPrice(isEnabledProductDetail.price_range).regular : true );
              
              let newprice:any = 0;
              newprice =  productSpecialPrice.value ? productSpecialPrice.value: productPrice.value;
              let oldprice:any = 0;
              if (productSpecialPrice.value){
                oldprice =  productCrossedOutPrice.value > productPrice.value ? productCrossedOutPrice.value : productPrice.value;
              }else{
                oldprice = productCrossedOutPrice.value;
              }
  
              const newPriceElement = div.querySelector('.new-price');
              newPriceElement.innerHTML = 'S$ '+newprice.toFixed(2); 
  
              const oldPriceElement = div.querySelector('.old-price');
              if(oldPriceElement){
                if(oldprice == 0){
                  oldPriceElement.remove();
                }else{
                  oldPriceElement.innerHTML = 'S$ '+oldprice.toFixed(2); 
                }
              }
            }
          });
          const flickElement = DYCSRecommendation.querySelector('.flicking-viewport');
  
          const dySkeletonListElement = document.querySelector('.dySkeleton_list');
          if(dySkeletonListElement){
            dySkeletonListElement.setAttribute('style', 'display:none');
          }
  
          DYCSRecommendation.removeAttribute('style');
          DYCSRecommendation.classList.replace('dyRecs', 'dyRecsdone');
  
          if(flickElement){
            const flicking = new window.Flicking(flickElement, {
            circular: true,
            });
            const arrow = new window.Flicking.Plugins.Arrow({ moveCount: 2 }); 
            flicking.addPlugins(arrow);
          } else{
            console.error('FlickError',flickElement);
          }
        }
    }

    const getDYProductQueryData = async () => {
      const skusArray = productSkus.value;
      const myGraphqlQuery =`
          query {
              dyproductinit(skus: ${JSON.stringify(skusArray)}) {
                items {
                  sku
                  name
                  calculated_lead_time
                  crossed_out_list_price
                  stock_status
                  width
                  height
                  depth
                  length
                  diameter
                  thumbnail{
                    url
                  }
                  secondary_image{
                    url
                  }
                  price_range {
                    maximum_price {
                        final_price {
                          currency
                          value
                        }
                        regular_price {
                          currency
                          value
                        }
                    }
                    minimum_price {
                        final_price {
                          currency
                          value
                        }
                        regular_price {
                          currency
                          value
                        }
                    }
                  }
                }
              }
            }`
      return await query(myGraphqlQuery);
    }

    const getBaseSearchQuery = () => ({
        filter: {
          sku: {
            eq: productSku.value,
          },
        },
    });
  
    const fetchProductBaseData = async (searchQuery = getBaseSearchQuery()) => {
        const result = await getProductList({
          ...searchQuery,
        });
        product.value = merge({}, product.value, result?.items[0] ?? null);  
    };

    const addtothecart = async (productSkua,productID) => {
        productSku.value = productSkua;
        const getProductIDElement = document.querySelector('.item-'+productID);
        const getaddtocartElement = getProductIDElement.querySelector('.sf-circle-icon');
        const getATCloadingElement = getProductIDElement.querySelector('.loadingicon');
        getATCloadingElement.removeAttribute('style');
        getaddtocartElement.setAttribute('style', 'display:none');

        await fetchProductBaseData();
        await addItemToCart({ product:product.value, quantity: 1, itmConfigurationSelected:'', canAddConfigItmToCart: false});

        if (product.value.__typename == 'SimpleProduct') {
          if (cartError.value?.addItem?.message) {          
            sendNotification({
              id: Symbol('product_added_to_cart'),
              message: cartError.value.addItem.message,
              persist: false,
              title: 'AddToCart Notification',
              type: 'danger',
              icon: '',
            });
          } else {
            sendNotification({
              id: Symbol('product_added_to_cart'),
              message: app.i18n.t('{product} was added to your cart.',{ product: product.value?.name },)as string,
              persist: false,
              title: 'Added To Cart',
              type: 'success',
              icon: 'check',
            });
          }
        }

        getaddtocartElement.removeAttribute('style');
        getATCloadingElement.setAttribute('style', 'display:none');
      }

      const fetchSmartObjectList = async () => {
        const getSmartObj = document.querySelector('.dy_recommendations_list');
        if(getSmartObj){
          const getSmartObjId = getSmartObj.id;
          const getSmartObjData = getSmartObj.getAttribute('data-id');
          DYO.smartObject(getSmartObjData, {target: getSmartObjId, inline: true});
        }
      }

    return {
        addtothecart,
        fetchDYLayout,
        fetchSmartObjectList,
    };
}

export default useFtDynamicYieldList;