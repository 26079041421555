<template functional>
  <component :is="props.link ? injections.components.SfLink : 'button'" :class="[
      data.class,
      data.staticClass,
      'ft-filter-button',
      {
        'is-active--button': props.isActive,
        'is-disabled--button': $options.buttonActive(props.link,props.disabled),
        'is-disabled--link': $options.linkActive(props.link, props.disabled),
      },
    ]" :style="[data.style, data.staticStyle]" :aria-disabled="props.disabled" :link="props.link" :type="props.type"
    :aria-label="props.ariaLabel" v-bind="data.attrs" v-on="!props.disabled ? listeners : {}">
    <slot />
  </component>
</template>
<script>
import { focus } from "@storefront-ui/vue/src/utilities/directives";
import SfLink from "@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue";
export default {
  name: "CategoryFilterButton",
  inject: {
    components: {
      default: { SfLink },
    },
  },
  directives: {
    focus,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
      validator: (value) => ["button", "submit", "reset"].includes(value),
    },
    link: {
      type: [String, Object],
      default: null,
    },
    ariaLabel: {
      type: String,
      default: "button",
    },
  },
  linkActive(link, disabled) {
    return link && disabled;
  },
  buttonActive(link, disabled) {
    return !link && disabled;
  },
};
</script>

<style lang="scss">
@import "~/components/customSFUI/shared/styles/components/atoms/CategoryFilterButton/CategoryFilterButton.scss";
</style>