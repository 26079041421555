











import {
  defineComponent,
  computed,
  useContext, 
  useFetch, 
  ref
} from '@nuxtjs/composition-api';
import { usePageStore } from '~/stores/page';

import CATEGORY from '~/modules/catalog/pages/category.vue';
import CMS_PAGE from '~/pages/Cms.vue';
import ERROR from '~/layouts/error.vue';
import PRODUCT from '~/modules/catalog/pages/product.vue';
import { RoutableInterface } from '~/modules/GraphQL/types';


export default defineComponent({
  name: 'PageResolver',
  components: {
    CATEGORY,
    CMS_PAGE,
    PRODUCT,
    ERROR
  },
  // activated () {
  //   // destroy keep alive product component   
  //   if(this?.routeData?.type == "PRODUCT"){
  //     this.$destroy();
  //   }
  // },
  // middleware: [
  //   'url-resolver',
  // ],
  setup() {
    // const { routeData: storeRouteData } = usePageStore();

    // const another_type = computed(() => storeRouteData?.type);
    const routeData = ref();

    const context = useContext();
    const { path } = context.route.value;
    const routeError = ref(null);

    const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');
    // console.log('middleware/url-resolver', clearUrl);

    useFetch(async () => {
      const { data, errors } = await context.app.$vsf.$magento.api.route(clearUrl);
      // console.log('middleware/url-resolver/result', { data, errors });

      const results: RoutableInterface | null = data?.route ?? null;

      if (!results || errors?.length) {
        routeError.value = { statusCode: 404 };
        // context.error({ statusCode: 404 })
      };
      routeData.value = results;
    })
    // console.log(routeData)
    return {
      routeData,
      clearUrl,
      routeError
    };
  },
});
